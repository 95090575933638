import React, { useState } from 'react'
import { selectClient } from '../../slices/searchUser.slice'
import { useSelector } from 'react-redux'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import searchInfoStyles from './search-info.styles'
import { ThemeButton } from '../../../../ui'
import { Delete } from '@material-ui/icons'
import { DeleteUserPopup } from '../delete-user/delete-user-popup'

export const SearchInfoComponent = () => {
  const classes = searchInfoStyles()
  const clientInfo = useSelector(selectClient)
  const { formatMessage } = useIntl()
  const [openDeletePopup, setOpenDeletePopup] = useState(false)

  if (!clientInfo || !clientInfo.full_name) {
    return null
  }

  return (
    <div className={classes.container}>
      <Alert severity="info" variant="outlined" className={classes.root}>
        <AlertTitle variant="h5" component="h2" style={{ textTransform: 'uppercase' }}>
          {clientInfo.full_name}
        </AlertTitle>
        <div className={classes.content}>
          <div>
            <Typography>
              {formatMessage({ id: 'pages.profile.user.fields.id' })}: {clientInfo.dni}
            </Typography>
            <Typography style={{ marginTop: '4px' }}>
              {formatMessage({ id: 'pages.profile.user.fields.email' })}: {clientInfo.email}
            </Typography>
            {!clientInfo.role && (
              <Typography style={{ marginTop: '4px' }} color="error">
                {formatMessage({ id: 'pages.profile.user.fields.notRegistered' })}
              </Typography>
            )}
          </div>
          <div>
            <ThemeButton
              color="default"
              className={classes.deleteButton}
              icon={<Delete />}
              onClick={() => setOpenDeletePopup(true)}
              disabled={!clientInfo.role}
            >
              {formatMessage({ id: 'pages.profile.header.delete-user' })}
            </ThemeButton>
          </div>
        </div>
      </Alert>

      <DeleteUserPopup
        openDialog={openDeletePopup}
        handleClose={() => setOpenDeletePopup(false)}
        clientInfo={clientInfo}
      />
    </div>
  )
}
