import { InvoicesReducer } from '../reducers'
import { useCallback, useReducer } from 'react'
import { INVOICES_INITIAL_STATE } from '../reducers/invoices-reducer.constants'
import { useInvoicesCalls } from './invoices-calls.hook'
import { useSelector } from 'react-redux'

export const useInvoicesReducer = () => {
  const [state, dispatch] = useReducer(InvoicesReducer, INVOICES_INITIAL_STATE)
  const { isTwoFactorAuthenticated } = useSelector((state) => state.global.authData)

  const { getInvoices, getInvoicePdf } = useInvoicesCalls()

  const getInvoicesTable = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: 'SET_LOADING',
      })
      getInvoices(config)
        .then(({ data }) => {
          dispatch({
            type: 'SET_TABLE_DATA',
            payload: {
              data: data,
              search: search,
            },
          })
        })
        .catch(() => {
          dispatch({
            type: 'SET_FAILURE',
          })
        })
    },
    [isTwoFactorAuthenticated]
  )

  const getInvoicePdfById = useCallback((id) => {
    dispatch({
      type: 'SET_LOADING',
    })
    getInvoicePdf(id)
      .then(({ data }) => {
        dispatch({
          type: 'SET_PDF_DATA',
          payload: data,
        })
      })
      .catch(() => {
        dispatch({
          type: 'SET_FAILURE',
        })
      })
  }, [])

  const filterTableData = useCallback(
    (data) => {
      dispatch({
        type: 'SET_LOADING',
      })
      dispatch({
        type: 'FILTER_TABLE_DATA',
        payload: data,
      })
    },

    []
  )

  const clearInvoicesData = useCallback((search) => {
    dispatch({
      type: 'SET_LOADING',
    })
    dispatch({
      type: 'SET_TABLE_DATA',
      payload: {
        data: [],
        search: search,
      },
    })
    return
  }, [])

  return {
    ...state,
    dispatch,
    getInvoicesTable,
    getInvoicePdfById,
    filterTableData,
    clearInvoicesData,
  }
}
