import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { TextInput, ThemeButton } from '../../../../ui'
import { useInvoicesCalls } from '../../hooks/invoices-calls.hook'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../profile/slices'

const { REACT_APP_ENVIRONMENT } = process.env
const REDSYS_PAYMENT_URL = 'https://sis.redsys.es/sis/realizarPago'
const REDSYS_PAYMENT_URL_TEST = 'https://sis-t.redsys.es:25443/sis/realizarPago'
const IS_TEST_ENV = REACT_APP_ENVIRONMENT !== 'Production'

export const PaymentPopup = ({ openDialog, handleClose, selectedInvoices }) => {
  const { formatMessage } = useIntl()
  const { payInvoices } = useInvoicesCalls()
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(false)
  const [paymentEnabled, setPaymentEnabled] = useState(false)
  const [formState, setFormState] = useState({})

  const totalAmount = selectedInvoices.reduce(
    (accValue, nextValue) => accValue + nextValue.importe_pendiente,
    0
  )

  const handleChangeForm = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
    setPaymentEnabled(formState['payer_name']?.length > 0 && formState['payer_surname']?.length > 0)
  }

  const handlePayment = async () => {
    setLoading(true)
    let paymentBody = {
      invoices: selectedInvoices.map((invoice) => invoice.factura),
      amount: selectedInvoices.reduce(
        (accValue, nextValue) => accValue + nextValue.importe_pendiente,
        0
      ),
      payer_name: formState['payer_name'],
      payer_surname: formState['payer_surname'],
    }
    const response = await payInvoices(paymentBody)
    if (response?.merchantParams) {
      const { merchantParams, signature, signatureVersion } = response
      redirectToRedsys({ merchantParams, signature, signatureVersion })
    }
    setLoading(false)
  }
  const redirectToRedsys = ({ merchantParams, signature, signatureVersion }) => {
    const form = document.createElement('form')
    form.action = IS_TEST_ENV ? REDSYS_PAYMENT_URL_TEST : REDSYS_PAYMENT_URL
    form.method = 'POST'
    form.style.display = 'none'

    // Add hidden inputs
    form.innerHTML = `
      <input type="hidden" name="Ds_SignatureVersion" value="${signatureVersion}" />
      <input type="hidden" name="Ds_MerchantParameters" value="${merchantParams}" />
      <input type="hidden" name="Ds_Signature" value="${signature}" />
    `

    document.body.appendChild(form)
    form.submit()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      maxWidth={'md'}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.invoices.paymentPopup.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={4} display={'flex'} gridGap={'8px'} flexDirection={'column'}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-line', marginBottom: '8px' }}
          >
            {formatMessage({ id: 'pages.invoices.paymentPopup.description' })}
          </Typography>
          {selectedInvoices.map((invoice) => (
            <Typography
              key={invoice.factura}
              variant="body2"
              color="textSecondary"
              style={{ whiteSpace: 'pre-line' }}
            >
              {`${invoice.factura}, ${new Intl.NumberFormat('es-ES', {
                style: 'currency',
                currency: 'EUR',
              }).format(invoice.importe_pendiente)}`}
            </Typography>
          ))}
          <div style={{ marginTop: '8px' }} />
          <TextInput
            type="text"
            required
            value={formState['payer_name']}
            name="payer_name"
            title={formatMessage({ id: 'pages.invoices.paymentPopup.payer_name' })}
            placeholder={formatMessage({
              id: 'pages.invoices.paymentPopup.payer_name.placeholder',
            })}
            onChange={handleChangeForm}
          />
          <TextInput
            type="text"
            required
            value={formState['payer_surname']}
            name="payer_surname"
            title={formatMessage({ id: 'pages.invoices.paymentPopup.payer_surname' })}
            placeholder={formatMessage({
              id: 'pages.invoices.paymentPopup.payer_surname.placeholder',
            })}
            onChange={handleChangeForm}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-line', fontWeight: 'bold', marginTop: '16px' }}
          >
            {`${formatMessage({
              id: 'pages.invoices.paymentPopup.totalAmount',
            })}: ${new Intl.NumberFormat('es-ES', {
              style: 'currency',
              currency: 'EUR',
            }).format(totalAmount)}`}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-line', marginBottom: '8px' }}
          >
            {formatMessage(
              { id: 'pages.invoices.paymentPopup.email-send-warning' },
              { email: user?.email }
            )}
          </Typography>
        </Box>
        <Box display="flex" justifyContent={'center'} gridGap={'16px'}>
          <ThemeButton
            type="button"
            onClick={handlePayment}
            loading={loading}
            disabled={!paymentEnabled}
          >
            {formatMessage({
              id: 'pages.invoices.paymentPopup.confirmPayment',
              defaultMessage: 'Confirmar pago',
            })}
          </ThemeButton>
          <ThemeButton color="default" onClick={handleClose}>
            {formatMessage({ id: 'global.cancel', defaultMessage: 'Cancelar' })}
          </ThemeButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
