import React, { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { parse } from 'qs'
import MaterialTable from 'material-table'
import { contractsTableStyles } from './contracts-table.styles'
import { ContractDetails } from '../details'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { useTheme } from '@material-ui/core/styles'
// import useMediaQuery from '@material-ui/core/useMediaQuery'
import { selectRole } from '../../../profile/slices'
import { selectClient } from '../../../searchUser/slices/searchUser.slice'
import { ContractsTableConstants } from './contracts-table.constants'
import { useContractsContext } from '../../contexts'

import { contractsDataPreparation } from '../../logics'

const localization = (formatMessage) => ({
  pagination: {
    labelDisplayedRows: formatMessage({ id: 'pagination.labelDisplayedRows' }),
    labelRowsSelect: formatMessage({ id: 'pagination.labelRowsSelect' }),
    firstAriaLabel: formatMessage({ id: 'pagination.firstAriaLabel' }),
    firstTooltip: formatMessage({ id: 'pagination.firstTooltip' }),
    previousAriaLabel: formatMessage({ id: 'pagination.previousAriaLabel' }),
    previousTooltip: formatMessage({ id: 'pagination.previousTooltip' }),
    nextAriaLabel: formatMessage({ id: 'pagination.nextAriaLabel' }),
    nextTooltip: formatMessage({ id: 'pagination.nextTooltip' }),
    lastAriaLabel: formatMessage({ id: 'pagination.lastAriaLabel' }),
    lastTooltip: formatMessage({ id: 'pagination.lastTooltip' }),
  },
  body: {
    emptyDataSourceMessage: formatMessage({ id: 'table.body.emptyDataSourceMessage' }),
  },
})

export function ContractsTableComponent() {
  const intl = useIntl()
  const { formatMessage } = intl
  // const [columns] = useState([])
  const location = useLocation()
  const history = useHistory()
  const role = useSelector(selectRole)
  const clientInfo = useSelector(selectClient)
  const { data, loading, getContractsTable, clearContractsData } = useContractsContext()
  const isTwoFactorAuthenticated = useSelector(
    (state) => state.global.authData.isTwoFactorAuthenticated
  ) // Estado de 2FA desde Redux
  const language = useSelector((state) => state.global.locale)

  useEffect(() => {
    const searchString = location.search.split('?')[1]

    const urlSearchParams = parse(searchString)
    const searchParams = {
      ...urlSearchParams,
    }
    if (role === 'ATR') {
      if (clientInfo?.full_name) {
        searchParams['client'] = clientInfo['dni']
        getContractsTable(searchParams)
      } else {
        clearContractsData(searchParams)
      }
    } else getContractsTable(searchParams)
  }, [clientInfo, getContractsTable, history, location.search, role, isTwoFactorAuthenticated])

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      headerStyle: contractsTableStyles.header,
      draggable: false,
      pageSize: 5,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  // const formattedColumns = useMemo(
  //   () =>
  //     columns.map((column) => {
  //       return {
  //         ...column,
  //         title: formatMessage({ id: `pages.contracts.table.columns.${column.field}.title` }),
  //         cellStyle: {
  //           ...contractsTableStyles.cell,
  //           ...column.cellStyle,
  //           minWidth: column.width,
  //         },
  //         emptyValue: () => <span>--</span>,
  //       }
  //     }),
  //   [columns], // eslint-disable-line react-hooks/exhaustive-deps
  // )

  const formattedColumns = useMemo(
    () =>
      ContractsTableConstants.map((column) => {
        return {
          ...column,
          title: formatMessage({ id: `pages.contracts.table.columns.${column.field}.title` }),
          cellStyle: {
            ...contractsTableStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
          },
          emptyValue: () => <span>--</span>,
        }
      }),
    [language] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const processedData = useCallback(contractsDataPreparation(data, { intl }, formattedColumns), [
    data,
    formattedColumns,
  ])

  return (
    <>
      <MaterialTable
        data={processedData}
        options={tableOptions}
        columns={formattedColumns}
        isLoading={loading}
        localization={useCallback(localization(formatMessage), [language])}
        detailPanel={(rowData) => <ContractDetails data={rowData} />}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </>
  )
}
