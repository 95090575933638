import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { requests } from '../../../core/ajax'
import { useDispatch } from 'react-redux'
import { setClientData } from '../slices/searchUser.slice'
import { fetchAndSetClientData } from '../../../core/utils'

export const useSearchCalls = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [error, setError] = useState(false)

  const redirectToProcessStatus = (status) => {
    history.push('/process/' + status)
  }

  const searchClient = useCallback(
    (client_id) =>
      new Promise((resolve, reject) => {
        requests
          .infoClient('?client=' + client_id)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  const deregister = useCallback(
    (nif) =>
      new Promise((resolve, reject) => {
        const config = { params: { client: nif } }

        requests
          .deregister(config)
          .then((response) => {
            redirectToProcessStatus('OK')
            resolve(response)
          })
          .catch(({ response }) => {
            redirectToProcessStatus('KO')
            reject(response?.status)
          })
          .finally(() => {
            fetchAndSetClientData(
              config.params.client,
              searchClient,
              dispatch,
              setClientData,
              setError
            )
          })
      }),
    [searchClient]
  )

  return {
    searchClient,
    deregister,
  }
}
