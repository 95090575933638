import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { InvoicesTableConstants, PendingInvoicesTableConstants } from './invoices-table.constants'
import MaterialTable from 'material-table'
import { Box, Checkbox, Tooltip } from '@material-ui/core'
import { invoicesTableStyles } from './invoices-table.styles'
import { useIntl } from 'react-intl'
import { invoiceDataPreparation } from '../../logics'
import { InvoicesDetailComponent } from '../detail'
import { parse } from 'qs'
import { useHistory, useLocation } from 'react-router-dom'
import { useInvoicesContext } from '../../contexts'
import { useSelector } from 'react-redux'
import { selectRole } from '../../../profile/slices'
import { selectClient } from '../../../searchUser/slices/searchUser.slice'

const localization = (formatMessage) => ({
  pagination: {
    labelDisplayedRows: formatMessage({ id: 'pagination.labelDisplayedRows' }),
    labelRowsSelect: formatMessage({ id: 'pagination.labelRowsSelect' }),
    firstAriaLabel: formatMessage({ id: 'pagination.firstAriaLabel' }),
    firstTooltip: formatMessage({ id: 'pagination.firstTooltip' }),
    previousAriaLabel: formatMessage({ id: 'pagination.previousAriaLabel' }),
    previousTooltip: formatMessage({ id: 'pagination.previousTooltip' }),
    nextAriaLabel: formatMessage({ id: 'pagination.nextAriaLabel' }),
    nextTooltip: formatMessage({ id: 'pagination.nextTooltip' }),
    lastAriaLabel: formatMessage({ id: 'pagination.lastAriaLabel' }),
    lastTooltip: formatMessage({ id: 'pagination.lastTooltip' }),
  },
  body: {
    emptyDataSourceMessage: formatMessage({ id: 'table.body.emptyDataSourceMessage' }),
  },
})

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export const InvoicesTableComponent = ({
  currentTab,
  setSelectedInvoices,
  handleSelectInvoice,
  selectedInvoices,
  classes,
  enablePayment,
}) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const [rowSelected, setRowSelected] = useState(null)
  const [pendingData, setPendingData] = useState([])
  const [tableOrder, setTableOrder] = useState({ orderBy: 2, sort: 'desc' })
  const location = useLocation()
  const history = useHistory()
  const role = useSelector(selectRole)
  const clientInfo = useSelector(selectClient)

  const language = useSelector((state) => state.global.locale)

  const { data, loading, getInvoicesTable, clearInvoicesData } = useInvoicesContext()

  const handleRowClick = useCallback((_, row) => {
    if (rowSelected != row) setRowSelected(row)
    else setRowSelected(null)
  }, [])

  useEffect(() => {
    const searchString = location.search.split('?')[1]

    const urlSearchParams = parse(searchString)
    const searchParams = {
      ...urlSearchParams,
    }
    if (role === 'ATR') {
      if (clientInfo?.full_name) {
        searchParams['client'] = clientInfo?.dni
        getInvoicesTable(searchParams)
      } else {
        clearInvoicesData(searchParams)
      }
    } else getInvoicesTable(searchParams)
  }, [getInvoicesTable, history, location.search, clientInfo, role])

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      headerStyle: invoicesTableStyles.header,
      rowStyle: (rowData) => ({
        backgroundColor: rowSelected?.factura === rowData.factura ? '#d6e0e6' : '#ffffff',
      }),
      draggable: false,
      pageSize: 10,
    }),
    [rowSelected] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const formattedColumns = useMemo(
    () => {
      const PendingCols = PendingInvoicesTableConstants(
        handleRowClick,
        formatMessage,
        enablePayment,
        handleSelectInvoice,
        selectedInvoices
      )
      const RestCols = InvoicesTableConstants(handleRowClick, formatMessage)
      const TableColumns = currentTab === 'pending_payment' ? PendingCols : RestCols

      return TableColumns.map((column, columnIndex) => {
        let cellStyle = {
          ...invoicesTableStyles.cell,
          ...column.cellStyle,
          minWidth: column.width,
        }
        const localizedValues = ['tipo_documento', 'estado_pago']
        const tooltipValues = ['estado_pago']
        let customData = (rowData) => {
          return rowData[column.field]
        }
        if (localizedValues.includes(column.field)) {
          customData = (rowData) =>
            tooltipValues.includes(column.field) ? (
              <Tooltip
                title={
                  <span style={{ fontSize: '13px', margin: '0 !important' }}>
                    {formatMessage({
                      id: `pages.invoices.table.values.descripcion_estado.${rowData[
                        'descripcion_estado'
                      ]?.toLowerCase()}.tooltip`,
                    })}
                  </span>
                }
              >
                <p>
                  {formatMessage({
                    id: `pages.invoices.table.values.${column.field}.${rowData[
                      column.field
                    ]?.toLowerCase()}`,
                  })}
                </p>
              </Tooltip>
            ) : (
              formatMessage({
                id: `pages.invoices.table.values.${column.field}.${rowData[
                  column.field
                ]?.toLowerCase()}`,
              })
            )
        }

        if (column.field !== 'actions') {
          column = { ...column, emptyValue: () => <span>--</span> }
        }
        if (column.field === 'actions') {
          const allChecked = selectedInvoices.length === pendingData.length
          const indeterminate = selectedInvoices.length > 0
          column = {
            ...column,
            title: enablePayment ? (
              <Checkbox
                checked={allChecked}
                indeterminate={indeterminate && !allChecked}
                onChange={(event, checked) =>
                  setSelectedInvoices(
                    indeterminate ? [] : pendingData.filter((_row) => _row.pagable)
                  )
                }
              />
            ) : (
              <></>
            ),
          }
        } else {
          column = {
            ...column,
            title: formatMessage({ id: `pages.invoice.table.columns.${column.field}.title` }),
          }
        }
        if (columnIndex == tableOrder.orderBy) {
          column = { ...column, defaultSort: tableOrder.sort }
        }

        return {
          ...column,
          cellStyle: cellStyle,
          render: column.render ? column.render : customData,
        }
      })
    },
    [language, currentTab, enablePayment, tableOrder, selectedInvoices] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const processedData = useCallback(invoiceDataPreparation(data, { intl }, formattedColumns), [
    data,
    formattedColumns,
  ])

  const onCloseDetail = () => {
    setRowSelected(null)
  }

  useEffect(() => {
    if (processedData.length)
      setPendingData(
        processedData.filter(
          (data) =>
            data.estado_pago === 'pending_payment' ||
            data.estado_pago === 'paid_but_not_conciliated'
        )
      )
  }, [processedData])

  return (
    <div className={classes.table}>
      <CustomTabPanel value={currentTab} index="pending_payment">
        <div
          className={`${classes.pendingTable} ${
            enablePayment ? classes.pendingTableWithSelection : ''
          }`}
        >
          <MaterialTable
            data={pendingData}
            // data={processedData}
            options={{
              ...tableOptions,
              // selection: enablePayment,
              // showTextRowsSelected: true,
              // showSelectAllCheckbox: true,
              selectionProps: (rowData) => {
                return {
                  disabled: !rowData.pagable,
                }
              },
              rowStyle: (rowData) => {
                let customStyle = {}
                if (!rowData.pagable) {
                  customStyle = { backgroundColor: '#d5d5d5' }
                }
                return {
                  ...customStyle,
                }
              },
            }}
            columns={formattedColumns}
            // onRowClick={handleRowClick}
            onOrderChange={(orderBy, orderDirection) => {
              setTableOrder({ orderBy: orderBy, sort: orderDirection })
            }}
            isLoading={loading}
            localization={useCallback(localization(formatMessage), [language])}
            // onSelectionChange={(data) => setSelectedInvoices(data.filter((_row) => _row.pagable))}
          />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={currentTab} index="all">
        <div className={classes.allTable}>
          <MaterialTable
            data={processedData}
            options={tableOptions}
            columns={formattedColumns}
            // onRowClick={handleRowClick}
            isLoading={loading}
            localization={useCallback(localization(formatMessage), [language])}
          />
        </div>
      </CustomTabPanel>
      <InvoicesDetailComponent
        invoice={rowSelected}
        onCloseDetail={onCloseDetail}
        showDownloadPayment={true}
      />
    </div>
  )
}
