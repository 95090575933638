import { persistReducer } from './persist-reducer.util'

export { useWindowSize } from './useWindowSize'
export { useWidth } from './useWidth' // similar to 'useWindowSize' but with material-ui theme breakpoints
export { encodeToBase64, downloadFile, covertStrToBlob, isACorrectFormat } from './fileUtils'
export * from './pwdUtils'
export * from './emailUtils'
export * from './idDocValidation'
export * from './localStorage'
export * from './cupsUtils'
export * from './fetchAndSetClientDataUtils'

export const usePersistReducer = persistReducer()
