import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { ThemeButton } from '../../../../ui'
import searchInfoStyles from '../search-info/search-info.styles'
import { useSearchCalls } from '../../hooks/search-calls.hook'

export const DeleteUserPopup = ({ openDialog, handleClose, clientInfo }) => {
  const { formatMessage } = useIntl()
  const classes = searchInfoStyles()
  const [loading, setLoading] = useState(false)
  const { deregister } = useSearchCalls()

  const handleAdminDeRegister = (nif) => {
    setLoading(true)
    deregister(nif)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      maxWidth={'md'}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.profile.header.delete-user' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={4} display={'flex'} gridGap={'8px'} flexDirection={'column'}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-line', marginBottom: '8px' }}
          >
            {formatMessage({ id: 'pages.profile.delete-user.popup.description' })}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-line', marginBottom: '8px' }}
          >
            {clientInfo.nombre + ' ' + clientInfo.apellidos}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-line', marginBottom: '8px' }}
          >
            {formatMessage({ id: 'pages.profile.user.fields.id' })}: {clientInfo.dni}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ whiteSpace: 'pre-line', marginBottom: '8px' }}
          >
            {formatMessage({ id: 'pages.profile.user.fields.email' })}: {clientInfo.email}
          </Typography>
        </Box>
        <Box display="flex" justifyContent={'center'} gridGap={'16px'}>
          <ThemeButton
            type="button"
            color="default"
            className={classes.deleteButton}
            onClick={() => {
              handleAdminDeRegister(clientInfo.dni)
            }}
            loading={loading}
          >
            {formatMessage({
              id: 'global.delete',
            })}
          </ThemeButton>
          <ThemeButton color="default" onClick={handleClose}>
            {formatMessage({ id: 'global.cancel', defaultMessage: 'Cancelar' })}
          </ThemeButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
