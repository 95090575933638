import { makeStyles } from '@material-ui/core'

const searchInfoStyles = makeStyles({
  root: {
    minWidth: 370,
    // border: '1px solid blue',
    fontSize: 30,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
  },
  title: {
    fontSize: 30,
  },
  pos: {
    marginBottom: 12,
  },
  phoneNumber: {
    paddingTop: 10,
  },
  // icon: {
  //   fontSize: 30
  // },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  deleteButton: {
    color: 'white',
    backgroundColor: '#ff4d4f',
    '&:hover': {
      backgroundColor: '#ff7875',
    },
  },
})

export default searchInfoStyles
